const activity = 'activity';
const allReservations = 'all-reservations';
const course = 'course';
const facilitiesList = 'course-list';
const home = 'home';
const loggedInUser = 'logged-in-user';
const paymentMethods = 'payment-methods';
const paymentRemoveCard = 'payment-remove-card';
const reservation = 'reservation';
const reservationCheckIn = 'reservation-checkin';
const reservationFinalizeCheckIn = 'finalize-checkin';
const reservationInvite = 'reservation-invite';
const reservationJoin = 'join-reservation';
const reservationSuggestedUsers = 'reservation-suggested-users';
const teeTime = 'tee-time';
const userRewards = 'user-rewards';

export const cacheKey = {
	activity,
	allReservations,
	course,
	facilitiesList,
	home,
	loggedInUser,
	paymentMethods,
	paymentRemoveCard,
	reservation,
	reservationCheckIn,
	reservationFinalizeCheckIn,
	reservationInvite,
	reservationJoin,
	reservationSuggestedUsers,
	teeTime,
	userRewards,
} as const;

export const authenticatedCacheKeys = [
	activity,
	allReservations,
	home,
	loggedInUser,
	paymentMethods,
	reservation,
	reservationCheckIn,
	userRewards,
];

export type CacheKey = (typeof cacheKey)[keyof typeof cacheKey];
