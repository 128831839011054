import { Field, Label, Select, DialogTrigger, Button, Dialog, Option } from '@troon/ui';
import { For, createSignal } from 'solid-js';
import { AddCard } from '../partials/add-card';
import { cardBrandToString } from '../modules/credit-cards';
import type { CreditCard } from '../graphql';

type Props = {
	cards: Array<CreditCard>;
	name?: string;
	onSelect?: (card: CreditCard) => void;
};

export function CreditCardSelector(props: Props) {
	const [selected, setSelected] = createSignal<string | undefined>(props.cards[0]?.id);

	return (
		<div class="flex flex-row items-end gap-x-4">
			<Field name="card" class="grow">
				<div class="sr-only">
					<Label>Choose card</Label>
				</div>
				<Select
					name={props.name ?? 'card'}
					onselect={(event) => {
						const card = props.cards.find((card) => card.id === event.currentTarget.value)!;
						setSelected(card.id);
						props.onSelect && props.onSelect(card);
					}}
				>
					<For
						each={props.cards}
						fallback={
							<Option disabled selected>
								No cards
							</Option>
						}
					>
						{(card) => (
							<Option value={card.id} selected={selected() === card.id}>
								{cardBrandToString[card.brand]} ending in {card.lastFour}
							</Option>
						)}
					</For>
				</Select>
			</Field>

			<div class="shrink grow-0">
				<DialogTrigger>
					<Button appearance="transparent">Add card</Button>
					<Dialog header="Add payment method" headerLevel="h2" key="add-payment-method">
						{(handleClose) => (
							<div class="min-w-64">
								<AddCard onCancel={handleClose} onSuccess={async () => handleClose()} />
							</div>
						)}
					</Dialog>
				</DialogTrigger>
			</div>
		</div>
	);
}
